import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSentryMiddleWare"] */ "/home/runner/work/kc-fonden/kc-fonden/src/app/layout.sentry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/components/analytics/CookiePolicyProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation","default"] */ "/home/runner/work/kc-fonden/kc-fonden/src/components/translate/TranslationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShadcnThemeProvider"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/theme-provider/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/kc-fonden/kc-fonden/src/shadcn/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden/kc-fonden/src/styles/custom-scrollbar.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/home/runner/work/kc-fonden/kc-fonden/src/trpc/react.tsx");
