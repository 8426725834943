'use client';

import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import type React from 'react';
import { useEffect } from 'react';

export const ClientSentryMiddleWare = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: session } = useSession();
  useEffect(() => {
    if (session) {
      Sentry.setUser({
        id: session.user.id,
        email: session.user.email,
        username: session.user.name,
      });
    }
  }, [session]);
  return <>{children}</>;
};
